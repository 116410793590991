import React from "react";
import { NavLink } from "react-router-dom";
import "./index.css";

const Cards = () => {
  return (
    <section classNameName="services" id="services">
      <div className="max-width">
        <h1 className="title my-3">
          <b>"What Be Safe Keep Safe can do for You?"</b>
        </h1>

        <div className="serv-content">
          <div className="card my-4">
            <div className="box">
              <div className="text">
                <b>1</b>
              </div>
              <p>
                We offer comprehensive service investigation/inspections for
                your buisnesses
                <br />
                <br />
              </p>
            </div>
          </div>
          <div className="card my-4">
            <div className="box">
              <div className="text">
                <b>2</b>
              </div>
              <p>
                We offer reviews of your findings of all types of buisnesses and
                how you can improve these with professional advice
              </p>
            </div>
          </div>
          <div className="card py-5 my-4 px-1">
            <div className="box">
              <div className="text">
                <b>3</b>
              </div>
              <p>
                We help and advise large and small buisnesses
                <br />
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="learn">
        <NavLink to="/" className="learnmore">
          Learn More Today
        </NavLink>
      </div>
    </section>
  );
};
export default Cards;
