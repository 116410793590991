import React from "react";
import logo from "./images/gif-720.gif";
import "./index.css";

const Home = () => {
  return (
    <section id="header" className="d-flex align-items-center">
      <div className="container-fluid nav_bg" id="home">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                <h1>
                  Your body can stand almost anything. It's your mind that you
                  have to convince.
                </h1>
                <div className="mt-3">
                  <a href="#footer" className="btn-get-started">
                    Get in Touch Now
                  </a>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 header-img">
                <img src={logo} className="img-fluid animated image-next" alt="logo" height="350" width="400"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    
  );
};

export default Home;
