import React, { useState } from "react";
import axios from "axios";
import "./index.css";

const Footer = () => {

  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");

  async function submit(e) {
    e.preventDefault();

    try {

        alert('Thank You For Contacting Us')
      await axios.post("http://localhost:8000/",{first,last,number,email,address,message })

    } 
    catch(error) {
      console.log(error);
    }
  }

  return (
    <section className="contactus-section" id="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10 mx-auto">
            <div className="row">
              <div className="contact-leftside col-12 col-lg-5">
                <div className="main-heading fw-bold call">
                  <h1>
                    Don't Be Shy
                    <br /> Give Us A Call
                  </h1>
                </div>
              </div>

              {/* form  */}
              <div className="contact-rightside col-12 col-lg-7">
                <form action="POST">
                  <div className="row" id="spaceabovefirst">
                    <div className="col-12 col-lg-6 contact-input-field">
                      <input
                        type="text"
                        onChange={(e) => {
                          setFirst(e.target.value);
                        }}
                        name=""
                        id=""
                        placeholder="First Name"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-lg-6 contact-input-field">
                      <input
                        type="text"
                        onChange={(e) => {
                          setLast(e.target.value);
                        }}
                        name=""
                        id=""
                        placeholder="Last Name"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-6 contact-input-field">
                      <input
                        type="text"
                        onChange={(e) => {
                          setNumber(e.target.value);
                        }}
                        name=""
                        id=""
                        placeholder="Phone Number"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-lg-6 contact-input-field">
                      <input
                        type="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        name=""
                        id=""
                        placeholder="Email ID"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 contact-input-field">
                      <input
                        type="text"
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        name=""
                        id=""
                        placeholder="Add Address"
                        className="form-control"
                      />
                    </div>
                    <div className="col-13">
                      <textarea
                        name="Message"
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                        id="message"
                        cols="70"
                        rows="70"
                        placeholder="Message"
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>

                  <div class="form-check form-checkbox-style ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    />
                    <label class="form-check-label" className="main-hero-para">
                      I agree to the Terms and Conditions
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-style w-100"
                    id="contact-submit"
                    onClick={submit}
                  >
                    {" "}
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Footer;
