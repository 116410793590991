import React from 'react'

export default function Footer2() {
  return (
    <div className='last-footer'>

            <div className="footer-left">
                <h5>privacy policy</h5>
            </div>
        
            </div>
  )
}
