import React from "react";
import "./index.css";
import logo1 from "./images/about1.jpg";
import logo2 from "./images/about2.jpg";
import logo3 from "./images/about3.jpg";
import logo4 from "./images/logo4.jpg";
import logo5 from "./images/logo5.jpg";

const About = () => {
  return (
    <section id="about">
      <div className="main-head">
        <div className="heading">
          <h1>
            <b>About Us</b>
          </h1>
        </div>
        <div className="para">
          <p>
            We aim to have a better and safer environment for employees
            and employers for any type of buisnesses. We also offer better
            culture and more benefits to organisations with implementing Health
            and Safety within their business structure. We are
            competent,certified professionals Chartered And members of iosh. We
            have 5 year experience in a large manufacturing company from
            logistics to processing and distribution departments and most
            agricultural businesses and many more. We offer Competent advise
            with zoom conference calls One on ones No business is small or large
            Feel free to call for a quote with no obligations Legal, moral and
            financial Risk assessment reviews
          </p>
        </div>
      </div>
      <div className="abt-content">
        <div className="aboutcard">
          <div className="aboutbox">
            <div className="imagee">
              <img src={logo1} alt="aboutImage" height="100" width="250" />
            </div>

            <p>
              We have 5 year experience in a large manufacturing company from
              logistic to processing and distribution departments and most
              agriculture buisnesses and many more
              <br />
            </p>
          </div>
        </div>
        <div className="aboutcard">
          <div className="aboutbox">
            <div className="imagee">
              <img src={logo2} alt="aboutImage" height="100" width="250" />
            </div>
            <p>
              We offer Competent advise with zoom conference calls one on one.
              No buisness is small or large, Feel free to call for a quote with
              no obligations
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className="aboutcard">
          <div className="aboutbox">
            <div className="imagee">
              <img src={logo3} alt="aboutImage" height="150" width="240" />
            </div>
            <p>
              Legal, moral and financial Risk assessment reviews
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>

      <section>
        <div className="lastimg">
          <img
            src={logo4}
            alt="aboutImage"
            height="640"
            width="640"
            className="left"
          />
          <img
            src={logo5}
            alt="aboutImage"
            height="640"
            width="640"
            id="logo5"
            className="right"
          />
        </div>
      </section>
    </section>
  );
};
export default About;
