import React from "react";
import logo from "./images/gif-720.gif";
import "./index.css";
import "./index.js";
import 'ionicons/dist/ionicons/ionicons.esm.js';
import 'ionicons/dist/ionicons/ionicons.js';


const Navbar = () => {
  return (
    <>
      <nav id="top">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>

        <ul className="menu">
          <li>
            <a href="#header">
              <ion-icon name="home"></ion-icon> Home
            </a>
          </li>
          <li>
            <a href="#services">
              <ion-icon name="information-circle"></ion-icon> What we do?
            </a>
          </li>
          <li>
            <a href="#about">
              <ion-icon name="person-circle"></ion-icon> About Us
            </a>
          </li>
          <li>
            <a href="#about">
              <ion-icon name="trophy"></ion-icon> Achivements
            </a>
          </li>
          <li>
            <a href="#footer" id="contact">
              Contact Us
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};
export default Navbar;
