import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Navbar from './Navbar';
import Home from './Home';
import Cards from './Cards';
import About from './About';
import Footer from './Footer';
import Footer2 from './Footer2';
import SideBtn from './SideBtn';
import Top from './Top';
import SupportEngine from './SupportEngine';

const App = () => {
  return (
    <>
      <Navbar />
      <Home />
      <Cards />
      <About />
      <Footer />
      <Footer2 />
      <SideBtn />
      <Top />
      <SupportEngine />
    </>
  );
};

export default App;
