import React from 'react'

export default function SideBtn() {
  return (
    <div>
      <button value="Get In Touch Today" id="rotate">
        <a href="#footer">Get In Touch Today</a>
      </button>
    </div>
  );
}
