import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import SupportAdmin from './SupportAdmin';

const path = window.location.pathname;

const rootElement = document.getElementById('root');

// Use createRoot instead of ReactDOM.render
const root = createRoot(rootElement);

// Wrap your rendering logic inside the createRoot function
root.render(
  <>
    <BrowserRouter basename = "/">
      {path.indexOf('/support') === -1 ? <App /> : <SupportAdmin />}
    </BrowserRouter>
  </>
);
