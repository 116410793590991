import React from 'react'

export default function Top() {
  return (
    <div className='upsideFlex'>
      <a href="#header" className='upsideScroll'>
        <ion-icon name="chevron-up-circle"></ion-icon>
      </a>
    </div>
  );
}
